<template>
  <v-container class="max-container">
    <top-banner :cancel="signOut" />
    <!-- <base-back /> -->
    <v-card flat color="black">
      <v-card-text>
        <div class="layout column">
          <div class="card-title-text v-card__title display-2">Choose the channel that's right for you
          </div>
        </div>
      </v-card-text>
      <div class="ad-box12">
        <div>
          <div><v-icon>
            mdi-check
          </v-icon>  Free trial, no credit card required.</div>
          <div><v-icon>
            mdi-check
          </v-icon>  Add a credit card to a new user and try it free for 7 days.</div>
          <div><v-icon>
            mdi-check
          </v-icon>  Change or cancel your channel anytime.</div>
        </div>
      </div>
      <div class="spacer-box" />
      <choose-channel ref="chooseChannel" :change-handle="changeHandle" :show-price="true" />
      <div class="total-price-box">
        <div>Monthly Price <span class="regular-price-text">${{ total_regular_price /100 }}</span></div>
        <!-- <div class="tip-text">
          After the 7 days free trial
        </div> -->
        <div class="total-price"><span class="now-only-text">now only</span>  ${{ total_price/100 }}</div>
      </div>
      <div class="button-box">
        <v-btn block depressed large class="tile text-none commit-subscription" color="cyan accent-4" :disabled="!enableNext" @click="subContinue()">Continue</v-btn>
      </div>
    </v-card>
    <c-snack-bar ref="csnackbar" />
  </v-container>
</template>

<script>

import logo from '@/assets/logo1.png'

import CSnackBar from '../components/snackbar'
import ChooseChannel from '../components/chooseChannel'
import TopBanner from '../components/topBanner'

export default {
  name: 'SubPlan',
  components: {
    CSnackBar,
    ChooseChannel,
    TopBanner
  },
  data: () => ({
    logo: logo,
    channelList: [],
    total_price: 0,
    total_regular_price: 0
  }),
  computed: {
    enableNext() {
      return this.channelList.some(item => item.check)
    }
  },
  created() {
    this.$nextTick(() => {
      const sub = this.$store.state.user.userInfo.subscription
      if (sub) {
        this.$router.push({ name: 'mychannel' })
      }
    })
  },
  methods: {
    changeHandle() {
      this.total_price = this.$refs.chooseChannel.calcPrice()
      this.total_regular_price = this.$refs.chooseChannel.calcRegular()
      this.channelList = this.$refs.chooseChannel.channelList
    },
    subContinue() {
      if (this.total_price === 0) {
        this.showNackBar('info', 'Please select at least one platform')
        return
      }
      const platforms = this.channelList.filter(item => item.check)
      this.$store.dispatch('user/setSubData', platforms)
      this.$router.push({ path: '/signup/creditoption' })
    },
    signOut() {
      this.$store.dispatch('user/FedLogOut').then(res => {
        this.$router.push({ 'name': 'login' })
      })
    }
  }
}
</script>
<style scoped lang="scss">
.platform-text{
    font-size: 20px;
    font-weight: 320;
}

.text--primary{
    font-size: 18px;
}

.total-price-box{
    color: white;
    text-align: center;
    font-size:medium;
    font-weight: bold;
    padding: 16px;
    .total-price{
        font-size:1.8em;
        font-weight: bold;
        // padding-top: 3px;
        color: #E040FB;
    }
}

.container .container--fluid .fill-height{
  background: white!important;
}

.image-item{
  border-radius: 9px;
  position: relative;
  height:90px;
  width:150px;
  .v-image{
    height:78px;
    border-radius: 5px;
  }
  .check-icon-box{
    position: relative;
    top: -82px;
    left: -1px;
    width: 20px;
    background: #E040FB;
    border-radius: 0 0 8px 0;
  }
}

.box-border-radius{
  border: 6px solid #E040FB;
}

.button-box{
  margin-top: 10px;
  .v-btn{
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
}

.channels-row{
  text-align:center;
  text-align: -webkit-center;
  .image-item{
    text-align: left!important;
  }
}

.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 10px;
}

.tip-text{
  color: white;
  font-size: smaller;
}

.now-only-text{
  font-size: 12px;
  color: #E040FB;
}

.card-title-text{
  color: #eae5e5!important;
  word-break: break-word;
}

.ad-box12{
  color: white;
  font-size: 12px;
  padding-left: 16px;
  i{
      color: #E040FB;
    }
}

.spacer-box{
  width: 100%;
  height: 20px;
}
</style>
